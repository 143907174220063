import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSupplier(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSupplier(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("/", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSuppliersInfo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/info", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // deleteSupplierAccount(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axiosIns
    //       .delete("/", { params: queryParams })
    //       .then((response) => resolve(response))
    //       .catch((error) => reject(error));
    //   });
    // },
  }
};
