import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import axiosIns from "@/libs/axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useSupplierDetail() {
  // Use toast
  const toast = useToast();

  const refSupplierListTable = ref(null);


  const tableColumns = [
    { key: "email", label: "電郵", sortable: true },
    { key: "mobile", label: "電話", sortable: true },
    { key: "contact_name", label: "聯絡人姓名", sortable: true },
    { key: "contact_phone", label: "聯絡人電話", sortable: true },
    { key: "is_active", label: "狀態", sortable: true },
  ];

  const perPage = ref(10);
  const totalSuppliers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const dataMeta = computed(() => {
    const localItemsCount = refSupplierListTable.value
      ? refSupplierListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSuppliers.value,
    };
  });

  watch(
    [currentPage, perPage, searchQuery],
    () => {
      refetchData();
    }
  );

  const supplierData = ref({})
  const refetchData = (ctx, callback) => {
    store
    .dispatch("app-supplier/fetchSupplier")
    .then((response) => {
      supplierData.value = response.data.supplier;
    })
    .catch((error) => {
      console.log("error when fetching supplier", error);
      if (error.response.status === 404) {
        supplierData.value = undefined;
      }
    });
  };  


  onMounted(() => {
    refetchData();
  });

  return {
    fetchSuppliers,
    tableColumns,
    perPage,
    currentPage,
    totalSuppliers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSupplierListTable,
    ability,
    refetchData,
    supplierData,
  };
}
