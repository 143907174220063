
<template>
  <div>
    <div v-if="supplierData && Object.keys(supplierData).length > 0">
      <b-card no-body class="p-2 bgCommon">
        <!-- contract Info: Top col -->
        <div
          class="d-flex flex-wrap align-items-center justify-content-between pb-2"
        >
          <h5
            class="mb-0 ml-0 mb-1 mb-sm-0 font-weight-bolder d-flex text-nowrap colorBlack"
          >
            <font-awesome-icon
              style="font-size: 18px"
              class="mr-50 ml-sm-1"
              icon="fa-solid fa-puzzle-piece"
            />
            公司資料詳情
          </h5>
          <div class="d-flex flex-wrap justify-content-end">
            <b-button
              class="text-nowrap mobile-w100 mb-1 mb-sm-0 px-3 mx-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="onSubmit"
              >上付放債人牌照文件
            </b-button>
            <b-button
              class="mobile-w100 mb-1 mb-sm-0 px-3 mx-1"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              返回
            </b-button>
          </div>
        </div>
        <hr />
        <div class="row mb-1">
          <b-col cols="12" md="6" class="info-wrap style-2 p-1">
            <div>
              <div
                class="d-flex aling-items-center text-underline mb-2 colorBlack"
              >
                公司資料
              </div>
              <div class="pl-1">
                <h5 class="colorBlack">
                  {{ supplierData.company_name_cn }}
                </h5>
                <h6 style="font-size: 1rem" class="colorBlack">
                  {{ supplierData.company_name_en }}
                </h6>
              </div>
            </div>
            <hr />
            <b-row class="px-2">
              <b-col md="6" class="info-item">
                <b class="info-item-label">公司註冊證書號碼 (CI)：</b>
                <span>{{ supplierData.ci_number }}</span>
              </b-col>

              <b-col md="6" class="info-item">
                <b class="info-item-label">電郵：</b>
                <span>{{ supplierData.email }}</span>
              </b-col>

              <b-col md="6" class="info-item">
                <b class="info-item-label">電話：</b>
                <span>{{ supplierData.mobile }}</span>
              </b-col>

              <b-col md="6" class="info-item">
                <b class="info-item-label">逾期還款時間：</b>
                <span>{{ supplierData.late_time }}</span>
              </b-col>

              <b-col md="6" class="info-item">
                <b class="info-item-label">聯絡人姓名：</b>
                <span>{{ supplierData.contact_name }}</span>
              </b-col>

              <b-col md="12" class="info-item">
                <b class="info-item-label">地址</b>
                <span>{{ supplierData.address }}</span>
              </b-col>

              <b-col md="12" class="info-item">
                <b class="info-item-label">放債人號碼：</b>
                <div class="d-flex">
                  <b-form-input
                    v-model="supplierData.moneylender_number"
                  ></b-form-input>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="text-nowrap ml-1 mobile-w100"
                    @click="onSubmit"
                  >
                    <feather-icon
                      size="16"
                      class="mr-1"
                      icon="CheckCircleIcon"
                    />
                    提交
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <!-- 子帳戶 -->
          <b-col cols="12" class="p-1 bgGrey">
            <div class="p-2">
              <div
                class="d-flex aling-items-center justify-content-between mb-2"
              >
                <h4 class="text-underline">
                  <span>子帳戶</span>
                </h4>
                <b-table
                  ref="refSupplierListTable"
                  class="position-relative sticky-rtl-tb"
                  :items="supplierData.childs"
                  responsive
                  :fields="tableColumns"
                  primary-key="id"
                  :sort-by.sync="sortBy"
                  show-empty
                  empty-text="未找到匹配的記錄"
                  :sort-desc.sync="isSortDirDesc"
                >
                  <!-- Column: is_active -->
                  <template #cell(is_active)="data">
                    <feather-icon
                      v-if="data.item.is_active"
                      icon="CheckIcon"
                      size="18"
                      class="mr-50 text-success"
                    />
                  </template>
                </b-table>
                <div class="mx-2 mb-2">
                  <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted"
                        >在{{ dataMeta.of }}個記錄中，正在顯示
                        {{ dataMeta.from }} 至 {{ dataMeta.to }}個記錄</span
                      >
                    </b-col>
                    <!-- Pagination -->
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRow"
                        :per-page="perPage"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-col>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BRow,
  BCol,
  BAlert,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BCollapse,
  BBadge,
  BForm,
  BOverlay,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormInvalidFeedback,
  BPagination,
  VBToggle,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted, ref, watch, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
// import ability from "@/libs/acl/ability";
import useSupplierDetail from "./useSupplierDetail";
import supplierStoreModule from "../supplierStoreModule";

export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
    BAlert,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BCollapse,
    BBadge,
    BOverlay,
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BPagination,
    flatPickr,
    vSelect,
  },
  data() {
    return {};
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  methods: {
    hide() {
      this.$router.replace("/payment/repayment/list");
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    onSubmit() {
      // convert is_active to number

      if (this.supplierData.is_active === false)
        this.supplierData.is_active = 0;

      this.supplierData.is_active = Number(this.supplierData.is_active);

      this.supplierData.childs.forEach((item) => {
        if (item.is_active === false) {
          item.is_active = 0;
        }
        item.is_active = item.is_active * 1;
      });

      this.onSubmitFormA();
    },
    onSubmitFormA() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-supplier/addSupplier", this.supplierData)
            .then((response) => {
              this.loading = false;
              this.$store.dispatch("app/fetchCoinsBalance");
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              this.$emit("refetch-data");

              if (this.supplierData.id == 0) {
                this.supplierData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = "app-supplier";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, supplierStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchSuppliers,
      tableColumns,
      perPage,
      currentPage,
      totalSuppliers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSupplierListTable,
      ability,
      refetchData,
      supplierData,
    } = useSupplierDetail();

    return {
      fetchSuppliers,
      tableColumns,
      perPage,
      currentPage,
      totalSuppliers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSupplierListTable,
      ability,
      refetchData,
      supplierData,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";
.info-wrap .info-item-label {
  min-width: 120px;
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 575px) {
}
</style>